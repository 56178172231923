import { mapGetters, mapActions } from 'vuex'
import authMixin from '@/mixins/auth-mixin'
const EventItem = () => import(/* webpackChunkName = "c-event-item" */'@/components/EventItem')
const AddEventForm = () => import(/* webpackChunkName = "c-add-event-form" */'@/components/AddEventForm')

export default {
  name: 'Schedule',
  mixins: [authMixin],
  components: {
    EventItem,
    AddEventForm
  },
  data () {
    return {
      visibleAddForm: false,
      editId: '',
      selectedTabs: 'ALL'
    }
  },
  created () {
    this.getEventsAction({
      success: () => {}
    })
  },
  computed: {
    ...mapGetters('user', ['token']),
    ...mapGetters('event', ['publics', 'teens', 'kids', 'events']),
    selectedEditEvent () {
      return this.events.find(event => event.id === this.editId) || {}
    },
    visiblePublic () {
      return this.selectedTabs === 'ALL' || this.selectedTabs === 'PUBLIC'
    },
    visibleTeen () {
      return this.selectedTabs === 'ALL' || this.selectedTabs === 'TEEN'
    },
    visibleKids () {
      return this.selectedTabs === 'ALL' || this.selectedTabs === 'KIDS'
    }
  },
  methods: {
    ...mapActions('event', ['getEventsAction', 'deleteEventAction']),
    handleSuccessDelete () {
      this.$swal({
        icon: 'success',
        title: 'Success Delete',
        showConfirmButton: false,
        timer: 1500
      })
    },
    handleDeleteEvent (id) {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        text: 'Anda tidak akan dapat mengembalikan ini!',
        icon: 'warning',
        confirmButtonText: 'Yes, hapus!',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteEventAction({
            success: this.handleSuccessDelete,
            data: {
              id: id,
              token: this.token
            }
          })
        }
      })
    },
    toggleAddForm (add = false) {
      if (add) this.editId = ''
      this.visibleAddForm = !this.visibleAddForm
    },
    handleEditEvent (id) {
      this.editId = id
      this.toggleAddForm()
    },
    changeTabs (val) {
      this.selectedTabs = val
    }
  }
}
